.link-validation-wrapper {
    border: 4px solid var(--very-darkish-blue);
    padding: 16px;
    border-radius: 4px;
    transition: background 0.15s;
}

.link-validation-wrapper:hover:not(.ignored) {
    cursor: pointer;
    border: 4px solid var(--darkish-blue);
}

.link-validation-wrapper:focus {
    border: 4px solid var(--very-darkish-blue-menu);
    background-color: var(--darkish-blue);
}

.link-validation-wrapper:not(.close):not(:last-child) {
    margin-bottom: 8px;
}

.link-validation-wrapper .grid-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-bottom: 16px;
}

.link-validation-wrapper .grid-group.header {
    align-items: center;
    grid-template-areas:
        'title url button'
        'epses ref .';
    grid-template-columns: minmax(0, 3fr) minmax(0, 9fr) 1fr;
}

.link-validation-wrapper .grid-group.header span:hover,
.link-validation-wrapper .grid-group.header a:hover {
    text-decoration: underline;
}

.link-validation-wrapper .grid-group.metadata {
    grid-template-columns: repeat(4, 1fr);
}

.link-validation-wrapper .grid-group.metadata > div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.link-validation-wrapper .grid-group.footer {
    grid-template-columns: 1.5fr 1fr;
}

.link-validation-wrapper .grid-group.footer > :first-child {
    align-self: center;
    justify-self: center;
}

.link-validation-wrapper .grid-group.footer > .img-container {
    display: flex;
    justify-content: center;
}

.link-validation-wrapper .grid-group.footer > .img-container > img {
    width: 72%;
}

.link-validation-wrapper .action-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    align-content: center;
}

.link-validation-wrapper .action-buttons > button {
    min-width: 100px;
}

.link-validation-wrapper .action-buttons > button:nth-child(even) {
    justify-self: start;
}

.link-validation-wrapper .action-buttons > button:nth-child(odd) {
    justify-self: end;
}

.link-validation-wrapper.close {
    animation-name: closing;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes closing {
    0% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0);
        opacity: 0;
        margin-bottom: 0;
        height: 0;
        padding: 0;
        border: 0;
    }
}
