.custom-dropdown label {
    font-size: 14px;
}

.wrap-content {
    display: flex;
    align-items: center;
}

.custom-dropdown span {
    display: flex;
    flex-direction: column;
}

.error-message {
    margin-top: 4px;
    font-size: 14px;
}