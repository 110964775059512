.action-buttons-wrap {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom : 8px;
}

.generate-report .report-type .option {
    margin-bottom: 8px;
    margin-top: 8px;
}

.generate-report .report-type .option > label {
    margin-left: 8px;
}