.match-validation-wrapper {
    border: 4px solid var(--very-darkish-blue);
    padding: 16px;
    border-radius: 4px;
    transition: background 0.15s;
}

.match-validation-wrapper:not(.close):not(:last-child) {
    margin-bottom: 8px;
}

.match-validation-wrapper .grid-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-bottom: 16px;
}

.match-validation-wrapper .grid-group.header span:hover,
.match-validation-wrapper .grid-group.header a:hover {
    text-decoration: underline;
}

.match-validation-wrapper .grid-group.metadata {
    grid-template-columns: repeat(4, 1fr);
}

.match-validation-wrapper .grid-group.metadata > div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.match-validation-wrapper .grid-group.footer {
    grid-template-columns: 1fr;
}

.match-validation-wrapper .grid-group.footer > * {
    align-self: center;
    justify-self: center;
}

.match-validation-wrapper .action-buttons {
    display: flex;
    gap: 8px;
    align-content: center;
}

.match-validation-wrapper .action-buttons > button {
    min-width: 100px;
}